/*@font-face {*/
/*    font-display: auto;*/
/*    font-family: 'RobotoSlab-ExtraBold';*/
/*    src: url("../fonts/RobotoSlab-ExtraBold.eot");*/
/*    src: url("../fonts/RobotoSlab-ExtraBold.eot") format('embedded-opentype'),*/
/*    url("../fonts/RobotoSlab-ExtraBold.otf") format('otf'),*/
/*    url("../fonts/RobotoSlab-ExtraBold.ttf") format('truetype'),*/
/*    url("../fonts/RobotoSlab-ExtraBold.woff") format('woff'),*/
/*    url("../fonts/RobotoSlab-ExtraBold.woff2") format('woff2');*/

/*}*/

/*@font-face {*/
/*    font-display: auto;*/
/*    font-family: 'RobotoSlab-Black';*/
/*    src: url("../fonts/RobotoSlab-Black.eot");*/
/*    src: url("../fonts/RobotoSlab-Black.eot") format('embedded-opentype'),*/
/*    url("../fonts/RobotoSlab-Black.otf") format('otf'),*/
/*    url("../fonts/RobotoSlab-Black.woff") format('woff'),*/
/*    url("../fonts/RobotoSlab-Black.woff2") format('woff2'),*/
/*    url("../fonts/RobotoSlab-Black.ttf") format('truetype');*/
/*}*/

@font-face {
    font-display: auto;
    font-family: 'RobotoSlab-Bold';
    src: url("../fonts/RobotoSlab-Bold.eot");
    src: url("../fonts/RobotoSlab-Bold.eot") format('embedded-opentype'),
    url("../fonts/RobotoSlab-Bold.otf") format('otf'),
    url("../fonts/RobotoSlab-Bold.ttf") format('truetype'),
    url("../fonts/RobotoSlab-Bold.woff") format('woff'),
    url("../fonts/RobotoSlab-Bold.woff2") format('woff2');
}

/*@font-face {*/
/*    font-display: auto;*/
/*    font-family: 'RobotoSlab-ExtraLight';*/
/*    src: url("../fonts/RobotoSlab-ExtraLight.eot");*/
/*    src: url("../fonts/RobotoSlab-ExtraLight.eot") format('embedded-opentype'),*/
/*    url("../fonts/RobotoSlab-ExtraLight.otf") format('otf'),*/
/*    url("../fonts/RobotoSlab-ExtraLight.ttf") format('truetype'),*/
/*    url("../fonts/RobotoSlab-ExtraLight.woff") format('woff'),*/
/*    url("../fonts/RobotoSlab-ExtraLight.woff2") format('woff2');*/
/*}*/

@font-face {
    font-display: auto;
    font-family: 'RobotoSlab-Light';
    src: url("../fonts/RobotoSlab-Light.eot");
    src: url("../fonts/RobotoSlab-Light.eot") format('embedded-opentype'),
    url("../fonts/RobotoSlab-Light.otf") format('otf'),
    url("../fonts/RobotoSlab-Light.ttf") format('truetype'),
    url("../fonts/RobotoSlab-Light.woff") format('woff'),
    url("../fonts/RobotoSlab-Light.woff2") format('woff2');
}

/*@font-face {*/
/*    font-display: auto;*/
/*    font-family: 'RobotoSlab-Medium';*/
/*    src: url("../fonts/RobotoSlab-Medium.eot");*/
/*    src: url("../fonts/RobotoSlab-Medium.eot") format('embedded-opentype'),*/
/*    url("../fonts/RobotoSlab-Medium.otf") format('otf'),*/
/*    url("../fonts/RobotoSlab-Medium.ttf") format('truetype'),*/
/*    url("../fonts/RobotoSlab-Medium.woff") format('woff'),*/
/*    url("../fonts/RobotoSlab-Medium.woff2") format('woff2');*/
/*}*/

@font-face {
    font-display: auto;
    font-family: 'RobotoSlab-Regular';
    src: url("../fonts/RobotoSlab-Regular.eot");
    src: url("../fonts/RobotoSlab-Regular.eot") format('embedded-opentype'),
    url("../fonts/RobotoSlab-Regular.otf") format('otf'),
    url("../fonts/RobotoSlab-Regular.ttf") format('truetype'),
    url("../fonts/RobotoSlab-Regular.woff") format('woff'),
    url("../fonts/RobotoSlab-Regular.woff2") format('woff2');
}

/*@font-face {*/
/*    font-display: auto;*/
/*    font-family: 'RobotoSlab-SemiBold';*/
/*    src: url("../fonts/RobotoSlab-SemiBold.eot");*/
/*    src: url("../fonts/RobotoSlab-SemiBold.eot") format('embedded-opentype'),*/
/*    url("../fonts/RobotoSlab-SemiBold.otf") format('otf'),*/
/*    url("../fonts/RobotoSlab-SemiBold.ttf") format('truetype'),*/
/*    url("../fonts/RobotoSlab-SemiBold.woff") format('woff'),*/
/*    url("../fonts/RobotoSlab-SemiBold.woff2") format('woff2');*/
/*}*/

/*@font-face {*/
/*    font-display: auto;*/
/*    font-family: 'RobotoSlab-Thin';*/
/*    src: url("../fonts/RobotoSlab-Thin.eot");*/
/*    src: url("../fonts/RobotoSlab-Thin.eot") format('embedded-opentype'),*/
/*    url("../fonts/RobotoSlab-Thin.otf") format('otf'),*/
/*    url("../fonts/RobotoSlab-Thin.ttf") format('truetype'),*/
/*    url("../fonts/RobotoSlab-Thin.woff") format('woff'),*/
/*    url("../fonts/RobotoSlab-Thin.woff2") format('woff2');*/
/*}*/

@font-face {
    font-display: auto;
    font-family: 'BebasNeue-Regular-Cyrilic';
    src: url("../fonts/BebasNeue-Regular-Cyrilic.eot");
    src: url("../fonts/BebasNeue-Regular-Cyrilic.eot") format('embedded-opentype'),
    url("../fonts/BebasNeue-Regular-Cyrilic.eot") format('otf'),
    url("../fonts/BebasNeue-Regular-Cyrilic.ttf") format('truetype'),
    url("../fonts/BebasNeue-Regular-Cyrilic.woff") format('woff'),
    url("../fonts/BebasNeue-Regular-Cyrilic.woff2") format('woff2');
}
